<template>
  <v-card tile :loading="loading" :disabled="loading">
    <v-card-title>
      <v-row>
        <v-col cols="12" xs="12" md="8">
          <BtnCircle
            tLabel="Atrás"
            tPosition="bottom"
            icon="mdi-arrow-left"
            color=""
            size="x-small"
            :to="{
              name: page_route ? page_route : 'home',
            }"
          />
          <span
            class="text-h6 ml-1"
            v-text="`${!loading ? `${data.folio} | ` : ''}${$route.meta.title}`"
          />
        </v-col>
        <v-col cols="12" xs="12" md="4" class="text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                fab
                x-small
                class="ml-1"
                color="info"
                @click.stop="faqs_dlg = true"
                :loading="faqs_ldg"
                :disabled="faqs.length == 0"
              >
                <v-icon> mdi-help </v-icon>
              </v-btn>
            </template>
            <span v-text="'Ayuda'" />
          </v-tooltip>
          <BtnCircle
            v-if="
              (type === 1 && login.permissions.rhas.update) ||
              (type === 2 && login.permissions.programmeds.update) ||
              (type === 3 && login.permissions.openings.update)
            "
            tLabel="Editar"
            tPosition="bottom"
            icon="mdi-pencil"
            color="info"
            size="x-small"
            :to="{
              name: page_route ? `${page_route}.editar` : 'home',
              params: { id: id },
            }"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row v-if="!loading">
        <v-col cols="12" v-if="data.insurance.judgment && !data.insured_id">
          <v-alert type="warning" dense>
            <v-row align="center">
              <v-col class="text-center">
                El asegurado no ha sido registrado en la base de datos
              </v-col>
              <v-col class="shrink">
                <v-btn
                  small
                  v-text="'Asignar'"
                  @click.prevent="insuredDialog"
                />
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
        <v-col cols="12" v-if="data.discharge">
          <v-alert type="success" dense>
            <div class="text-center">
              El ingreso fue dado de alta el {{ data.discharge }}
            </div>
          </v-alert>
        </v-col>

        <v-col cols="12" class="text-center">
          <v-badge
            v-if="login.permissions.rha_amounts.read"
            bordered
            :color="
              getColorBadge(data.total_amounts, data.total_status_amout_color)
            "
            overlap
            :content="`${data.total_amounts} | ${data.total_status_amout}`"
            left
          >
            <v-btn
              class="white--text"
              x-small
              :color="
                getColorBadge(data.total_amounts, data.total_status_amout_color)
              "
              dark
              :to="{
                name: page_route ? `${page_route}.amounts` : 'home',
                params: { id: id },
              }"
            >
              <v-icon x-small v-text="'mdi-currency-usd'" />
              <span class="mx-1">Montos</span>
            </v-btn>
          </v-badge>

          <v-badge
            v-if="login.permissions.rha_evolutions.read"
            bordered
            :color="getColorBadge(data.total_evolutions, 'purple')"
            overlap
            :content="data.total_evolutions"
            style="padding-left: 10px"
          >
            <v-btn
              x-small
              :color="getColorBadge(data.total_evolutions, 'purple')"
              dark
              :to="{
                name: page_route ? `${page_route}.evolution` : 'home',
                params: { id: id },
              }"
            >
              <v-icon x-small v-text="'mdi-note-search'" />
              <span class="mx-1">N. Evolución</span>
            </v-btn>
          </v-badge>

          <v-badge
            v-if="login.permissions.rha_surgicals.read"
            bordered
            :color="getColorBadge(data.total_surgicals, 'deep-purple')"
            overlap
            :content="data.total_surgicals"
            style="padding-left: 10px"
          >
            <v-btn
              x-small
              :color="getColorBadge(data.total_surgicals, 'deep-purple')"
              dark
              :to="{
                name: page_route ? `${page_route}.expedient` : 'home',
                params: { id: id },
              }"
            >
              <v-icon x-small v-text="'mdi-hospital-box'" />
              <span class="mx-1">N. Quirúrgicas</span>
            </v-btn>
          </v-badge>

          <v-badge
            v-if="login.permissions.rha_administratives.read"
            bordered
            :color="getColorBadge(data.total_administratives, 'indigo')"
            overlap
            :content="data.total_administratives"
            style="padding-left: 10px"
          >
            <v-btn
              class="white--text"
              depressed
              x-small
              :color="getColorBadge(data.total_administratives, 'indigo')"
              dark
              :to="{
                name: page_route ? `${page_route}.notes` : 'home',
                params: { id: id },
              }"
            >
              <v-icon x-small v-text="'mdi-note-multiple'" />
              <span class="mx-1">N. Administrativas</span>
            </v-btn>
          </v-badge>

          <v-badge
            v-if="login.permissions.rha_visits_surveys.read"
            bordered
            :color="getColorBadge(data.total_visits_surveys, 'brown')"
            overlap
            :content="data.total_visits_surveys"
            style="padding-left: 10px"
          >
            <v-btn
              dark
              depressed
              x-small
              :color="getColorBadge(data.total_surveys, 'orange')"
              :to="{
                name: page_route ? `${page_route}.visit_survey` : 'home',
                params: { id: id },
              }"
            >
              <v-icon x-small v-text="'mdi-clipboard-list'" />
              <span class="mx-1">Visitas y Encuestas</span>
            </v-btn>
          </v-badge>

          <v-badge
            v-if="login.permissions.rha_demands.read"
            bordered
            :color="
              getColorBadgeDemands(
                data.demand_icon,
                'orange',
                data.demand_type_id
              )
            "
            overlap
            :content="data.demand_icon"
            style="padding-left: 10px"
          >
            <v-btn
              dark
              depressed
              x-small
              :color="
                !data.demand_responsed
                  ? getColorBadge(data.demand_icon, 'orange')
                  : 'grey'
              "
              :to="{
                name: page_route ? `${page_route}.demands` : 'home',
                params: { id: id },
              }"
            >
              <v-icon x-small v-text="'mdi-text-box-check'" />
              <span class="mx-1">Peticiones</span>
            </v-btn>
          </v-badge>

          <v-badge
            v-if="login.permissions.rha_openings.read"
            bordered
            :color="getColorBadge(data.total_openings, 'indigo')"
            overlap
            :content="data.total_openings"
            style="padding-left: 10px"
          >
            <v-btn
              dark
              depressed
              x-small
              :color="getColorBadge(data.total_openings, 'indigo')"
              :to="{
                name: page_route ? `${page_route}.openings` : 'home',
                params: { id: id },
              }"
            >
              <v-icon x-small v-text="'mdi-file-delimited'" />
              <span class="mx-1">Aperturas</span>
            </v-btn>
          </v-badge>

          <v-badge
            v-if="login.permissions.rha_loas.read"
            bordered
            :color="getColorBadge(data.total_loas, 'pink')"
            overlap
            :content="data.total_loas"
            style="padding-left: 10px"
          >
            <v-btn
              dark
              depressed
              x-small
              :color="getColorBadge(data.total_loas, 'pink')"
              :to="{
                name: page_route ? `${page_route}.loas` : 'home',
                params: { id: id },
              }"
            >
              <v-icon x-small v-text="'mdi-file-compare'" />
              <span class="mx-1">LOA</span>
            </v-btn>
          </v-badge>

          <v-badge
            v-if="login.permissions.rha_letters.read"
            bordered
            :color="
              data.color_letter
                ? getColorBadgeLetters(
                    data.total_letters,
                    data.last_letter_review,
                    'brown'
                  )
                : 'grey'
            "
            overlap
            :content="data.total_letters + ' | ' + data.last_letter_review"
            style="padding-left: 10px"
          >
            <v-btn
              dark
              depressed
              x-small
              :color="
                data.color_letter
                  ? getColorBadge(data.total_letters, 'brown')
                  : 'grey'
              "
              :to="{
                name: page_route ? `${page_route}.letters` : 'home',
                params: { id: id },
              }"
            >
              <v-icon x-small v-text="'mdi-file-certificate'" />
              <span class="mx-1">Cartas</span>
            </v-btn>
          </v-badge>

          <v-badge
            bordered
            :color="
              getColorBadge(data.contractor_detail.express_letter, 'green')
            "
            overlap
            :content="data.contractor_detail.express_letter ? 'E' : ''"
            style="padding-left: 5px"
          >
            <v-btn
              class="ml-5"
              x-small
              :color="getColorBadge(data.total_documents, 'cyan')"
              dark
              :to="{
                name: page_route ? `${page_route}.documents` : 'home',
                params: { id: id },
              }"
            >
              <v-icon x-small v-text="'mdi-file-multiple'" />
              <span class="mx-1">Documentos</span>
            </v-btn>
          </v-badge>

          <v-btn
            v-if="login.permissions.rha_evolutions.read"
            top
            dark
            class="ml-3"
            depressed
            x-small
            :color="
              getColorBadge(
                data.total_surgicals + data.total_evolutions,
                'pink'
              )
            "
            @click="doctorsResumeDialog"
          >
            <v-icon x-small v-text="'mdi-stethoscope'" />
            <span class="mx-1">Resumen de médicos</span>
          </v-btn>

          <v-btn
            v-if="
              (login.role_id === 1 || login.role_id === 2) &&
              !data.discharge &&
              (!data.insured_id ||
                (data.insured_id &&
                  (data.total_letters == 0 ||
                    (data.total_letters > 0 && data.last_rha_letter_id))))
            "
            top
            class="ml-2"
            x-small
            color="success"
            dark
            @click="dischargeDialog()"
          >
            <v-icon x-small v-text="'mdi-check'" />
            <span class="mx-1">Alta</span>
          </v-btn>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="`GENERAL (${type_name})`" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Usuario" :value="data.user.email" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Aseguradora" :value="data.insurance.name" />
                </v-col>
                <v-col cols="12" xs="12" md="1" v-if="type === 3">
                  <ViewData
                    label="Petición"
                    :value="data.petition ? 'SI' : 'NO'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Estado" :value="data.entity_name" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Municipio" :value="data.municipality_name" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Hospital"
                    :value="data.provider.trade_name"
                    :subvalue="
                      data.provider.provider_emails &&
                      (login.role_id == 1 ||
                        login.role_id == 10 ||
                        login.role_id == 11 ||
                        login.role_id == 12)
                        ? data.provider.provider_emails
                        : null
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Alta hospitalaria"
                    :value="data.hospital_discharged"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'ASEGURADO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-btn
                  v-if="data.insured_id"
                  dark
                  small
                  @click="sinisterRepDialog"
                >
                  VER SINIESTRALIDAD
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="ID SM / Póliza"
                    :value="data.enrollment"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="Nombre"
                    :value="data.name"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="A. Paterno"
                    :value="data.first_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="A. Materno"
                    :value="data.second_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="F. nacimiento"
                    :value="data.insured ? data.insured.birthday : '-'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="Vigencia"
                    :value="data.insured ? data.insured.validity : '-'"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="9">
                  <ViewData
                    :class="`text-caption${
                      !data.insured_id ? ' orange--text text--darken-3' : ''
                    }`"
                    label="Contratante"
                    :value="data.contractor"
                  />
                </v-col>
                <!-- <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Es titular"
                    :value="!data.policyholder ? 'NO' : 'SI'"
                  />
                </v-col> -->
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Tipo asegurado"
                    :value="data.insured.insured_type.insured_type"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="T. ingreso"
                    :value="
                      data.rha_description
                        ? data.rha_description.name
                        : 'NO REGISTRADO'
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="12">
                  <ViewData label="T. internamiento" :value="data.internment" />
                </v-col>
                <v-col cols="12" xs="12" md="12">
                  <ViewData
                    label="CPT"
                    :value="
                      data.cpt && data.cpt.cpt ? data.cpt.cpt : 'NO REGISTRADO'
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="12">
                  <ViewData
                    label="ICD"
                    :value="
                      data.icd && data.icd.icd ? data.icd.icd : 'NO REGISTRADO'
                    "
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4" v-if="!data.policyholder">
                  <ViewData
                    label="Nombre (Titular)"
                    :value="data.dependent_name"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4" v-if="!data.policyholder">
                  <ViewData
                    label="A. Paterno (Titular)"
                    :value="data.dependent_first_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="4" v-if="!data.policyholder">
                  <ViewData
                    label="A. Materno (Titular)"
                    :value="data.dependent_second_surname"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'MÉDICO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-spacer />
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Nombre" :value="data.doctor_name" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="A. paterno"
                    :value="data.doctor_first_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="A. materno"
                    :value="data.doctor_second_surname"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Especialidad"
                    :value="data.specialty_type.specialty_type"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'ADMISIÓN'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Núm. de paciente o episodio hospital"
                    :value="data.episode"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3" v-if="type != 2">
                  <ViewData
                    label="Tipo"
                    :value="data.rha_admission_type.rha_admission_type"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Fecha de ingreso"
                    :value="data.admission_date"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3" v-if="type != 2">
                  <ViewData
                    label="Hora de ingreso"
                    :value="data.admission_time"
                  />
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  md="3"
                  v-if="data.admission_date_report && login.role_id < 3"
                >
                  <ViewData
                    label="Fecha ingreso reporte"
                    :value="data.admission_date_report"
                  />
                </v-col>
                <v-col
                  cols="12"
                  xs="12"
                  md="3"
                  v-if="data.discharge_report && login.role_id < 3"
                >
                  <ViewData
                    label="Fecha alta reporte"
                    :value="data.discharge_report"
                  />
                </v-col>
                <v-col cols="12">
                  <ViewData label="Diagnóstico" :value="data.diagnosis" />
                </v-col>
                <v-col cols="12">
                  <ViewData label="Procedimiento" :value="data.process" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData
                    label="Núm. de reporte | Siniestro"
                    :value="data.sinister_number"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="3" v-if="type != 2">
                  <ViewData label="Habitación" :value="data.room" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Correo electrónico" :value="data.email" />
                </v-col>
                <v-col cols="12" xs="12" md="3">
                  <ViewData label="Teléfono" :value="data.phone" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'DOCUMENTOS'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" v-for="(item, i) in data.documents" :key="i">
                  <v-row>
                    <v-col cols="12" xs="12" md="5">
                      <ViewData
                        :label="`Documento (${i + 1})`"
                        :value="item.document.document"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="5">
                      <ViewData
                        :label="'Descripción'"
                        :value="item.description"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="2">
                      <v-tooltip left>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            class="mr-1"
                            v-on="on"
                            fab
                            x-small
                            color="warning"
                            :href="url_documents + '/rhas/' + item.url"
                            target="_blank"
                          >
                            <v-icon v-text="'mdi-download'" />
                          </v-btn>
                        </template>
                        <span v-text="'Descargar'" />
                      </v-tooltip>
                    </v-col>
                    <v-col cols="12">
                      <v-divider />
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card tile>
            <v-toolbar elevation="1" dense>
              <v-toolbar-title>
                <span class="text-caption" v-text="'REGISTRO'" />
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items> </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-row dense>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="Creación"
                    :value="!loading && data.created_by.email"
                    :subvalue="data.created_at"
                  />
                </v-col>
                <v-col cols="12" xs="12" md="6">
                  <ViewData
                    label="U. actualización"
                    :value="!loading && data.updated_by.email"
                    :subvalue="data.updated_at"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="12"
          v-if="
            (type === 1 && login.permissions.rhas.delete) ||
            (type === 2 && login.permissions.programmeds.delete) ||
            (type === 3 && login.permissions.openings.delete)
          "
        >
          <BtnCircle
            tLabel="Cancelar ingreso"
            tPosition="top"
            icon="mdi-sticker-remove-outline"
            color="error"
            size="x-small"
            :click="desactivate"
            :loading="loading"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <!-- DIALOGS-->
    <v-dialog v-model="insured_dialog" scrollable persistent max-width="1000">
      <v-card
        tile
        :loading="insured_dialog_loading"
        :disabled="insured_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Asignar asegurado'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insured_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" />
            <v-col cols="12" v-if="!insured_rha">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'ASEGURADO'" />
                    <small
                      class="text-caption"
                      v-text="' | Pulsa enter sobre un campo para buscar'"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="info"
                          icon
                          @click.prevent="insuredSearch"
                        >
                          <v-icon v-text="'mdi-account-search'" />
                        </v-btn>
                      </template>
                      <span v-text="'Buscar'" />
                    </v-tooltip>
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="ID"
                        v-model="insured_search.enrollment"
                        type="text"
                        v-on:keyup.enter="insuredSearch"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="Nombre"
                        v-model="insured_search.name"
                        type="text"
                        v-on:keyup.enter="insuredSearch"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="A. Paterno"
                        v-model="insured_search.first_surname"
                        type="text"
                        v-on:keyup.enter="insuredSearch"
                        dense
                      />
                    </v-col>
                    <v-col cols="12" xs="12" md="3">
                      <v-text-field
                        label="A. Materno"
                        v-model="insured_search.second_surname"
                        type="text"
                        v-on:keyup.enter="insuredSearch"
                        dense
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      v-if="insured_search_fail && !insured_form"
                    >
                      <v-alert type="warning" dense>
                        Asegurado no encontrado en sistema
                      </v-alert>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" v-if="insured_table">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span
                      class="text-caption"
                      v-text="'RESULTADO DE BUSQUEDA'"
                    />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items> </v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-simple-table dense>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <td v-text="'ID'" />
                              <td v-text="'Asegurado'" />
                              <td v-text="''" />
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(v, i) in insureds" :key="i">
                              <td v-text="v.enrollment" />
                              <td
                                v-text="
                                  `${v.name} ${v.first_surname} ${v.second_surname}`
                                "
                              />
                              <td>
                                <v-tooltip left>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      v-on="on"
                                      icon
                                      small
                                      color="warning"
                                      @click="insuredData(v.id)"
                                    >
                                      <v-icon v-text="'mdi-check'" />
                                    </v-btn>
                                  </template>
                                  <span v-text="'Seleccionar'" />
                                </v-tooltip>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" v-if="insured_rha && insured_rha.insured_id">
              <v-card tile>
                <v-toolbar elevation="1" dense>
                  <v-toolbar-title>
                    <span class="text-caption" v-text="'ASEGURADO'" />
                  </v-toolbar-title>
                  <v-spacer />
                  <v-toolbar-items>
                    <v-tooltip v-if="data.insured.id" left>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="warning"
                          icon
                          @click.prevent="insuredRemove"
                        >
                          <v-icon v-text="'mdi-account-reactivate'" />
                        </v-btn>
                      </template>
                      <span v-text="'Volver a buscar'" />
                    </v-tooltip>
                  </v-toolbar-items>
                </v-toolbar>
                <v-spacer />
                <v-card-text>
                  <v-form
                    v-on:submit.prevent
                    ref="insured_form"
                    lazy-validation
                  >
                    <v-row dense>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData label="ID" :value="insured_rha.enrollment" />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData label="Nombre" :value="insured_rha.name" />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="A. Paterno"
                          :value="insured_rha.first_surname"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="A. Materno"
                          :value="insured_rha.second_surname"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData label="Póliza" :value="insured_rha.policy" />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="Núm. de asegurado"
                          :value="insured_rha.credential"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <ViewData
                          label="Contratante"
                          :value="insured_rha.contractor"
                        />
                      </v-col>
                      <v-col cols="12" xs="12" md="3">
                        <v-switch
                          label="Titular"
                          v-model="insured_rha.policyholder"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="4"
                        v-if="!insured_rha.policyholder"
                      >
                        <v-text-field
                          label="Nombre (Titular)*"
                          v-model="insured_rha.dependent_name"
                          :rules="rules.required"
                          maxlength="50"
                          type="text"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="4"
                        v-if="!insured_rha.policyholder"
                      >
                        <v-text-field
                          label="A. Paterno (Titular)*"
                          v-model="insured_rha.dependent_first_surname"
                          :rules="rules.required"
                          maxlength="25"
                          type="text"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        xs="12"
                        md="4"
                        v-if="!insured_rha.policyholder"
                      >
                        <v-text-field
                          label="A. Materno (Titular)"
                          v-model="insured_rha.dependent_second_surname"
                          maxlength="25"
                          type="text"
                          dense
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        v-if="insured_rha.contractor_express_letter"
                        class="text-center"
                      >
                        <v-alert type="success" dense>
                          El asegurado cuenta con servicio de carta exprés por
                          medio del contratante
                        </v-alert>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              v-if="insured_rha && insured_rha.insured_id"
              cols="12"
              xs="12"
              md="12"
              class="text-right"
            >
              <BtnCircle
                tLabel="Editar datos del asegurado"
                tPosition="top"
                icon="mdi-pencil"
                color="info"
                size="x-small"
                :click="insuredSubmit"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="doctors_resume_dialog"
      scrollable
      persistent
      max-width="1000px"
    >
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title
            v-text="'Ingreso Hospitalario | Resumen de médicos'"
          />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="doctors_resume_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" xs="12" md="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">#</th>
                      <th class="text-left">Médico</th>
                      <th class="text-left">Especialidad</th>
                      <th class="text-left">Tipo</th>
                      <th class="text-left">Visitas</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v, i) in doctors_resume" :key="i">
                      <td v-text="parseInt(i) + 1" />
                      <td v-text="v.name" />
                      <td v-text="v.speciality" />
                      <td v-text="v.type_name" />
                      <td v-text="v.type_id > 3 ? 'N/A' : v.visits" />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="discharge_dialog"
      scrollable
      persistent
      max-width="1000px"
    >
      <v-card
        tile
        :loading="discharge_dialog_loading"
        :disabled="discharge_dialog_loading"
      >
        <v-toolbar dark dense>
          <v-toolbar-title v-text="'Ingreso Hospitalario | Alta'" />
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="discharge_dialog = false">
              <v-icon v-text="'mdi-close'" />
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-form v-on:submit.prevent ref="discharge_form" lazy-validation>
            <v-row>
              <v-col cols="12" />
              <v-col cols="12">
                <v-card tile>
                  <v-toolbar elevation="1" dense>
                    <v-toolbar-title>
                      <span class="text-caption" v-text="'DATOS'" />
                    </v-toolbar-title>
                    <v-spacer />
                    <v-toolbar-items> </v-toolbar-items>
                  </v-toolbar>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="12" sm="12" md="6">
                        <DatePicker
                          label="Fecha de alta*"
                          :model.sync="discharge.date"
                          :rules="rules.required"
                          clean
                        />
                      </v-col>
                      <v-col cols="12" sm="12" md="6">
                        <v-text-field
                          label="Hora de alta*"
                          v-model="discharge.time"
                          :rules="rules.required"
                          type="time"
                          dense
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" class="text-right">
                <BtnCircle
                  tLabel="Aceptar"
                  tPosition="top"
                  icon="mdi-check"
                  color="success"
                  size="x-small"
                  :click="dischargeSubmit"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="sinister_rep_dlg" scrollable persistent max-width="1800">
      <v-card
        tile
        :disabled="sinister_rep_dlg_ldg"
        :loading="sinister_rep_dlg_ldg"
      >
        <v-toolbar dark dense>
          <v-toolbar-title> SINIESTRALIDAD </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="sinister_rep_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="sinister_rep">
          <v-row dense>
            <v-col cols="12" />
            <v-col cols="12">
              <v-divider />
            </v-col>
            <v-col cols="12">
              <h4 v-text="'SINIESTRALIDAD'" />
            </v-col>
          </v-row>
          <v-row dense v-for="(icd, i) in sinister_rep.list" :key="i">
            <v-col cols="12">
              <span class="text-caption" v-text="icd.code + ' | ' + icd.icd" />
            </v-col>
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'#'" />
                      <th v-text="'Tipo'" />
                      <th v-text="'Proveedor'" />
                      <th v-text="'T. Prov. | Espec.'" />
                      <th v-text="'Folio'" />
                      <th v-text="'Fecha'" />
                      <th v-text="'Padecimiento'" />
                      <th v-text="'Siniestro'" />
                      <th v-text="'Núm. Siniestro'" />
                      <th v-text="'Deducible'" />
                      <th v-text="'Coaseguro %'" />
                      <th v-text="'Facturado'" />
                      <th v-text="'Monto'" />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in icd.items" :key="i">
                      <td v-text="`${i + 1}`" />
                      <td v-text="item.type" />
                      <td v-text="item.provider" />
                      <td v-text="item.provider_type" />
                      <td v-text="item.folio" />
                      <td v-text="item.date" />
                      <td v-text="item.accident ? 'ACCIDENTE' : 'ENFERMEDAD'" />
                      <td
                        v-text="item.initial ? 'INICIAL' : 'COMPLEMENTARIO'"
                      />
                      <td v-text="item.sinister_number" />
                      <td v-text="numberFormat(item.deducible_amount)" />
                      <td v-text="item.coinsurance" />
                      <td>
                        <v-icon small :color="item.bill ? 'success' : ''">
                          mdi-{{ item.bill ? "check" : "close" }}
                        </v-icon>
                      </td>
                      <td v-text="numberFormat(item.amount)" />
                      <td>
                        <v-tooltip v-if="item.comment" left>
                          <template v-slot:activator="{ on }">
                            <v-btn
                              v-on="on"
                              fab
                              dark
                              x-small
                              class="ml-1"
                              color="info"
                              @click="commentDialog(item)"
                            >
                              <v-icon> mdi-message-draw </v-icon>
                            </v-btn>
                          </template>
                          <span v-text="'Seg. médico comentario'" />
                        </v-tooltip>
                      </td>
                    </tr>
                    <tr>
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td />
                      <td>
                        <b v-text="numberFormat(icd.amount)" />
                      </td>
                      <td />
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="comment_dlg" scrollable persistent max-width="600px">
      <v-card v-if="comment" tile>
        <v-toolbar dark dense>
          <v-toolbar-title> FOLIO {{ comment.folio }}</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="comment_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-spacer />
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-title>
                  <h2 class="text-caption" v-text="'SEG. MÉDICO COMENTARIO'" />
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p v-text="comment.comment" />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <FaqDlg v-model="faqs_dlg" :faqs="faqs" />
  </v-card>
</template>

<script>
import Axios from "axios";
import {
  URL_API,
  headersToken,
  msgConfirm,
  msgAlert,
  rules,
  URL_DOCUMENTS,
} from "../../control";
import { show, destroy, index } from "../../requests/pageRequest";
import BtnCircle from "../../components/BtnCircle.vue";
import ViewData from "../../components/ViewData.vue";
import DatePicker from "../../components/DatePicker.vue";
import FaqDlg from "../../components/FaqDlg.vue";

export default {
  components: {
    BtnCircle,
    ViewData,
    DatePicker,
    FaqDlg,
  },
  data() {
    return {
      rules: rules(),
      page_route: null,
      type: null,
      type_name: "",
      login: this.$store.getters.getLogin,
      id: this.$route.params.id ? parseInt(this.$route.params.id) : null,
      loading: true,
      data: null,
      insured_search: {},
      insured_rha: null,
      insured_dialog: false,
      insured_dialog_loading: false,
      insured_search_fail: false,
      insureds: [],
      insured_table: false,
      url_documents: URL_DOCUMENTS,
      doctors_resume: {},
      doctors_resume_dialog: false,
      discharge_dialog: false,
      discharge_dialog_loading: false,
      discharge: {},
      sinister_rep: null,
      sinister_rep_dlg_ldg: false,
      sinister_rep_dlg: false,
      comment: null,
      comment_dlg: false,
      faqs: [],
      faqs_dlg: false,
      faqs_ldg: true,
    };
  },
  methods: {
    getData() {
      this.loading = true;

      show("rha", this.login.token, this.id).then((response) => {
        this.data = response;
        //console.log(this.data);

        switch (this.data.rha_type_id) {
          case 1:
            this.type_name =
              this.data.rha_admission_type_id === 4
                ? "CIRUGÍA PROGRAMADA"
                : "INGRESO HOSPITALARIO";
            break;
          case 2:
            this.type_name = "CIRUGÍA PROGRAMADA";
            break;
          case 3:
            this.type_name = "APERTURA";
            break;
        }

        this.loading = false;
      });
    },
    insuredDialog() {
      this.insured_search = {
        enrollment: "",
        name: "",
        first_surname: "",
        second_surname: "",
        insurance_id: null,
      };

      this.insureds = [];
      this.insured_table = false;
      this.insuredRemove();
      this.insured_dialog = true;
    },
    insuredSearch() {
      if (
        this.insured_search.enrollment != "" ||
        this.insured_search.name != "" ||
        this.insured_search.first_surname != "" ||
        this.insured_search.second_surname != ""
      ) {
        this.insured_dialog_loading = true;
        this.insured_search_fail = false;

        this.insured_search.insurance_id = this.data.insurance_id;

        Axios.post(
          URL_API + "/insureds/search",
          this.insured_search,
          headersToken(this.login.token)
        ).then((response) => {
          this.insureds = response.data.data;

          if (this.insureds.length === 1) {
            this.insuredData(this.insureds[0].id);
          } else {
            if (this.insureds.length === 0) {
              this.insured_search_fail = true;
            } else {
              this.insured_table = true;
            }
          }

          this.insured_dialog_loading = false;
        });
      } else {
        this.$swal.fire(
          msgAlert(
            "warning",
            "Utiliza al menos un campo para la búsqueda de asegurado"
          )
        );
      }
    },
    insuredData(id) {
      this.insured_dialog_loading = true;

      show("insureds", this.login.token, id).then((response) => {
        this.insured_rha = {
          insured_id: response.id,
          enrollment: response.enrollment,
          name: response.name,
          first_surname: response.first_surname,
          second_surname: response.second_surname,
          policy: response.policy,
          credential: response.credential,
          contractor: response.contractor.name,
          contractor_express_letter: response.contractor.express_letter,
          policyholder: this.data.policyholder,
          dependent_name: this.data.dependent_name,
          dependent_first_surname: this.data.dependent_first_surname,
          dependent_second_surname: this.data.dependent_second_surname,
          updated_by_id: this.login.id,
        };

        this.insured_table = false;
        this.insured_dialog_loading = false;
      });
    },
    insuredRemove() {
      this.insured_rha = null;
    },
    insuredSubmit() {
      if (this.$refs.insured_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma la edición del asegurado?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.loading = true;
              this.insured_dialog_loading = true;

              Axios.patch(
                URL_API + "/rha/" + this.id,
                this.insured_rha,
                headersToken(this.login.token)
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );

                if (response.data.success) {
                  this.insured_dialog = false;
                  this.getData();
                } else {
                  console.log(response.data.message);
                }
              });

              this.loading = false;
              this.insured_dialog_loading = false;
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    desactivate() {
      this.$swal
        .fire(msgConfirm("¿Confirma la cancelación del ingreso?"))
        .then((response) => {
          if (response.isConfirmed) {
            this.loading = true;

            destroy("rha", this.login.token, this.id, this.login.id).then(
              (response) => {
                this.$swal.fire(
                  msgAlert(
                    response.success ? "success" : "error",
                    response.message
                  )
                );

                response.success
                  ? this.$router.push({ name: this.page_route })
                  : console.log(response.message);
              }
            );
          }
        });
    },
    doctorsResumeDialog() {
      index("rhas/doctors/" + this.id, this.login.token, []).then(
        (response) => {
          this.doctors_resume = response.data;
          this.doctors_resume_dialog = true;
        }
      );
    },
    dischargeDialog() {
      this.discharge = {
        id: this.id,
        time: "",
        date: "",
      };

      this.discharge_dialog = true;
    },
    dischargeSubmit() {
      if (this.$refs.discharge_form.validate()) {
        this.$swal
          .fire(msgConfirm(`¿Confirma el alta del ingreso?`))
          .then((response) => {
            if (response.isConfirmed) {
              this.discharge_dialog_loading = true;

              Axios.post(
                URL_API + "/rhas/discharge",
                this.discharge,
                headersToken(this.login.token)
              ).then((response) => {
                this.$swal.fire(
                  msgAlert(
                    response.data.success ? "success" : "error",
                    response.data.message
                  )
                );

                if (response.data.success) {
                  this.$router.push({
                    name: this.page_route,
                  });
                } else {
                  console.log(response.data.message);
                }

                this.discharge_dialog_loading = false;
              });
            }
          });
      } else {
        this.$swal.fire(
          msgAlert(
            "error",
            "Formulario invalido, revisa los detalles señalados"
          )
        );
      }
    },
    getColorBadge(counter, color) {
      if (counter !== 0) return color;
      return "grey";
    },
    getColorBadgeVisitsSurveys(visits, surveys) {
      if (surveys === 0) return "orange";
      else {
        if (visits !== 0) return "brown";
      }
      return "grey";
    },
    getColorBadgeDemands(counter, color, type) {
      if (counter !== 0) {
        if (type === 4) return "red";
        return color;
      }
      return "grey";
    },
    getColorBadgeLetters(letters, letter_review, color) {
      if (letters === 0) {
        return "grey";
      } else if (letter_review !== "T") {
        return "orange";
      }
      return color;
    },
    numberFormat(v) {
      return Number(parseFloat(v).toFixed(2)).toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    },
    sinisterRepDialog() {
      this.sinister_rep = null;
      this.sinister_rep_dlg_ldg = true;
      this.sinister_rep_dlg = true;

      Axios.get(
        URL_API +
          "/insureds/sinister/report?insured_id=" +
          this.data.insured.id +
          "&icd_id=0",
        headersToken(this.login.token)
      ).then((res) => {
        this.sinister_rep = res.data.data;
        this.sinister_rep_dlg_ldg = false;
      });
    },
    commentDialog(item) {
      this.comment = item;
      this.comment_dlg = true;
    },
  },
  mounted() {
    Axios.get(
      URL_API + "/faqs/faq_interfaces/" + this.$route.meta.title,
      headersToken(this.login.token)
    ).then((resp) => {
      this.faqs = resp.data.data;
      this.faqs_ldg = false;
    });

    this.page_route = this.$route.name;
    this.page_route = this.page_route.split(".");
    this.page_route = this.page_route[0];

    switch (this.page_route) {
      case "admission_request":
        this.type = 1;
        break;
      case "programaciones":
        this.type = 2;
        break;
      case "aperturas":
        this.type = 3;
        break;
      case "facturacion":
        this.type = 4;
        break;
    }

    this.getData();
  },
};
</script>